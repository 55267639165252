import React, { useEffect, useMemo, useRef, useState } from "react";
import Navbar from "../components/Navbar";
import { RiUserFill } from "react-icons/ri";
import { MdOutlineWifi } from "react-icons/md";
import {
  FaExternalLinkAlt,
  FaRegHeart,
  FaSearch,
  FaStar,
  FaUserCircle,
  FaYoutube,
} from "react-icons/fa";
import { FaCrown, FaRegCirclePlay } from "react-icons/fa6";
import { HiMail } from "react-icons/hi";
import { IoGift } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import { VscThreeBars } from "react-icons/vsc";
import JsonData from "../json/data.js";
import { environment } from "../utils/environment.js";

const categories1 = [
  {
    startContent: <div className="w-2 h-2 bg-red-500 rounded-full"></div>,
    text: "Live Stream",
  },
  {
    startContent: (
      <div className="flex gap-1 text-orange-500 items-center">
        <FaCrown /> Full
      </div>
    ),
    text: "Videos",
  },
  {
    startContent: <FaRegHeart className="text-pink-500" />,
    text: "Chat Room",
  },
  {
    text: "Celebrities",
  },
  {
    text: "Channels",
  },
  {
    startContent: <img src="/image/vpn.svg" alt="icon" />,
    text: "Free VPN",
  },
  {
    startContent: <img src="/image/game.svg" alt="icon" />,
    text: "Interactive Game",
  },
  {
    startContent: <img src="/image/girl-friend.svg" alt="icon" />,
    text: "AI Companion",
  },
];

const categories = [
  { icon: FaYoutube, text: "Video Hub" },
  { icon: FaStar, text: "Kenna James" },
  { icon: FaStar, text: "Nathan Bronson" },
  { icon: FaStar, text: "Will Havoc" },
  { text: "Large Assets" },
  { text: "Blonde Hair" },
  { text: "Oral Content" },
  { text: "Food Challenges" },
  { text: "Intense Content" },
  { text: "Activity-Based Content" },
  { text: "High Definition Videos" },
  { text: "Action-Packed" },
];

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const HomePage = () => {
  const randomIndex = useMemo(() => getRandomInt(0, JsonData.length - 1), []);
  const selected = useMemo(() => JsonData[randomIndex], [randomIndex]);
  const [topToBottom, setTopToBottom] = useState(true);
  const [modalHeight, setModalHeight] = useState(0);
  const [height, setHeight] = useState(400);
  const modal = useMemo(() => {
    if (typeof window !== undefined) {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const query = params.get(environment.queryString);

      if (query && query === environment.queryStringValue) {
        var randomFourDigit = Math.floor(1000 + Math.random() * 9000);
        var url = new URL(window.location.href);
        url.searchParams.set("tls", randomFourDigit);
        window.history.replaceState(null, null, url.toString());
        setTimeout(() => {
          let modalElement = document.querySelector(".modal-content");
          if (modalElement) setModalHeight(modalElement.clientHeight);
        }, 500);
        return true;
      } else {
        return false;
      }
    }
  }, []);
  const ref = useRef();

  const otherData = useMemo(() => {
    let array = JsonData.filter((_, ind) => ind !== randomIndex);
    let newArray = [];
    while (array.length) {
      let index = getRandomInt(0, array.length - 1);
      newArray.push(array[index]);
      array.splice(index, 1);
    }
    return newArray;
  }, [randomIndex]);

  const randomCategories = useMemo(() => {
    let array = categories.filter((_, ind) => ind !== randomIndex);
    let newArray = [];
    while (array.length) {
      let index = getRandomInt(0, array.length - 1);
      newArray.push(array[index]);
      array.splice(index, 1);
    }
    return newArray;
  }, [randomIndex]);

  const handleVideoClick = () => {
    if (typeof window !== undefined) {
      const bodyHeight = document.body.offsetHeight;
      const totalScroll = window.scrollY + window.screen.height;
      if (topToBottom) {
        if (totalScroll < bodyHeight && topToBottom)
          window.scrollBy({ top: 400, behavior: "smooth" });
        else {
          window.scrollBy({ top: -400, behavior: "smooth" });
          setTopToBottom(false);
        }
      } else {
        if (window.scrollY < window.screen.availHeight) {
          window.scrollBy({
            top: -window.screen.availHeight,
            behavior: "smooth",
          });
          setTopToBottom(true);
        } else window.scrollBy({ top: -400, behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    if (ref.current) {
      let elemHeight = ref.current.offsetTop;
      if (elemHeight) setHeight(elemHeight + 50);
    }
  }, [ref]);

  return (
    <>
      <div
        className={`${modal ? "overflow-hidden" : ""}`}
        style={{ height: modal ? modalHeight : "auto" }}
      >
        <Navbar />
        <div className="p-4">
          <section className="m-auto flex items-start justify-center pb-8 md:container gap-10">
            <div className="relative flex max-w-[950px] w-full flex-col items-start gap-2">
              <h1 className="mb-4 text-3xl font-extrabold leading-tight tracking-tighter text-black md:text-4xl">
                Latest news of Donald Trump's Presidency
              </h1>
              <div className="mb-10 w-full overflow-hidden rounded-xl">
                <img
                  src="/image/trump.png"
                  alt="Donald Trump's Presidency"
                  className="aspect-video w-full object-cover object-top"
                  style={{ height }}
                />
              </div>
              <div className="flex justify-center w-full">
                <ins
                  className="adsbygoogle"
                  style={{
                    display: "inline-block",
                    width: "970px",
                    height: "250px",
                  }}
                  data-ad-client="ca-pub-1968935214221292"
                  data-ad-slot="5753418344"
                ></ins>
              </div>
              <div className="py-8 font-sans text-gray-800 w-full">
                <header className="mb-8">
                  <h1 className="text-3xl font-bold mb-2">
                    The recent presidential directive on domestic mineral
                    development
                  </h1>
                  <p className="text-gray-600 italic">
                    Strategic Resource Production, March 2025 Executive Action
                    Review
                  </p>
                </header>

                <section className="mb-10">
                  <h2 className="text-2xl font-bold mb-4">Key Findings</h2>
                  <p className="mb-4">
                    The new presidential directive marks a major change in the
                    federal resource policy that set-ground works for the
                    development of domestic minerals. This piece analyzes the
                    multilateral strategy created to bolster national supply
                    chains, with an eye to economic and security considerations.
                  </p>
                  <p>
                    Current global market conditions have exposed
                    vulnerabilities in the supply chains of strategic materials
                    with significant concentration in certain geographic areas.
                    The administration's response centers on speeding up
                    domestic production throughout the entire production cycle.
                  </p>
                </section>

                <section className="mb-10">
                  <h2 className="text-2xl font-bold mb-4">Contextual Data:</h2>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>
                      Domestic production accounts for less than 15% of current
                      strategic mineral demand
                    </li>
                    <li>
                      In fact, analysis of projected requirements for the
                      technology sector show 400% growth by 2040.
                    </li>
                    <li>
                      It takes, on average, over eight years to develop an
                      extraction project
                    </li>
                  </ul>
                </section>

                <section className="mb-10">
                  <h2 className="text-2xl font-bold mb-4">
                    Policy Framework Components:
                  </h2>
                  <div className="grid md:grid-cols-2 gap-6">
                    <div className="bg-gray-50 p-4 rounded">
                      <h3 className="font-bold mb-2">
                        Regulatory Modernization
                      </h3>
                      <p>
                        New interdepartmental coordination protocols and
                        review processes
                      </p>
                    </div>
                    <div className="bg-gray-50 p-4 rounded">
                      <h3 className="font-bold mb-2">Resource Access</h3>
                      <p>
                        Land management priorities for federally owned land
                        adjusted
                      </p>
                    </div>
                    <div className="bg-gray-50 p-4 rounded">
                      <h3 className="font-bold mb-2">Financial Architecture</h3>
                      <p>New financing mechanisms and investment incentives</p>
                    </div>
                    <div className="bg-gray-50 p-4 rounded">
                      <h3 className="font-bold mb-2">Industrial Development</h3>
                      <p>
                        Seamless support for entire manufacturing ecosystems
                      </p>
                    </div>
                  </div>
                </section>

                <div className="flex justify-center w-full">
                  <ins
                    className="adsbygoogle"
                    style={{ display: "block" }}
                    data-ad-client="ca-pub-1968935214221292"
                    data-ad-slot="3528245107"
                    data-ad-format="auto"
                    data-full-width-responsive="true"
                  ></ins>
                </div>

                <section className="mb-10">
                  <h2 className="text-2xl font-bold mb-4">
                    Implementation Outlook
                  </h2>
                  <p>
                    30-60 Days: Take initial agency actions, with full
                    operational capacity expected in 18 months.
                  </p>
                </section>

                <section className="mb-10">
                  <h2 className="text-2xl font-bold mb-4">
                    The Classification of Strategic Materials
                  </h2>
                  <h3 className="text-xl font-semibold mb-2">
                    Wider Definitions
                  </h3>
                  <p className="mb-4">
                    The order creates a detailed classification scheme for the
                    materials that support economic and national security
                    interests, expanding existing systems but also adding new
                    elements:
                  </p>

                  <div className="grid md:grid-cols-3 gap-4">
                    <div className="border p-4 rounded">
                      <h4 className="font-bold mb-2">
                        Energy Transition Materials
                      </h4>
                      <ul className="list-disc pl-5 space-y-1">
                        <li>Lithium compounds</li>
                        <li>Cobalt derivatives</li>
                        <li>Graphite products</li>
                        <li>Nickel alloys</li>
                      </ul>
                    </div>
                    <div className="border p-4 rounded">
                      <h4 className="font-bold mb-2">
                        Industrial Foundation Metals
                      </h4>
                      <ul className="list-disc pl-5 space-y-1">
                        <li>Copper materials</li>
                        <li>Aluminum grades</li>
                        <li>Zinc applications</li>
                        <li>Steel additives</li>
                      </ul>
                    </div>
                    <div className="border p-4 rounded">
                      <h4 className="font-bold mb-2">
                        Advanced Technology Elements
                      </h4>
                      <ul className="list-disc pl-5 space-y-1">
                        <li>Rare earth compounds</li>
                        <li>Semiconductor-grade materials</li>
                        <li>Precision alloy components</li>
                        <li>Optical quality minerals</li>
                      </ul>
                    </div>
                  </div>
                </section>

                <section className="mb-10">
                  <h2 className="text-2xl font-bold mb-4">
                    Integration of Value Chain of Production
                  </h2>
                  <p className="mb-4">
                    The policy lays down a unique methodology of creating
                    material by acknowledging five crucial stages in
                    the production chain:
                  </p>
                  <div className="bg-blue-50 p-6 rounded-lg mb-4">
                    <h4 className="font-bold text-blue-800 mb-3 text-center">
                      Material Development Continuum
                    </h4>
                    <div className="flex flex-wrap sm:flex-nowrap justify-between items-center">
                      <div className="text-center p-2 w-full">
                        <div className="bg-white p-3 rounded-full shadow-md mx-auto mb-2 w-16 h-16 flex items-center justify-center font-bold">
                          I
                        </div>
                        <p className="text-sm font-medium">
                          Resource Identification
                        </p>
                      </div>
                      <div className="text-center p-2 w-full">
                        <div className="bg-white p-3 rounded-full shadow-md mx-auto mb-2 w-16 h-16 flex items-center justify-center font-bold">
                          II
                        </div>
                        <p className="text-sm font-medium">
                          Extraction Operations
                        </p>
                      </div>
                      <div className="text-center p-2 w-full">
                        <div className="bg-white p-3 rounded-full shadow-md mx-auto mb-2 w-16 h-16 flex items-center justify-center font-bold">
                          III
                        </div>
                        <p className="text-sm font-medium">
                          Material Processing
                        </p>
                      </div>
                      <div className="text-center p-2 w-full">
                        <div className="bg-white p-3 rounded-full shadow-md mx-auto mb-2 w-16 h-16 flex items-center justify-center font-bold">
                          IV
                        </div>
                        <p className="text-sm font-medium">
                          Manufacturing Inputs
                        </p>
                      </div>
                      <div className="text-center p-2 w-full">
                        <div className="bg-white p-3 rounded-full shadow-md mx-auto mb-2 w-16 h-16 flex items-center justify-center font-bold">
                          V
                        </div>
                        <p className="text-sm font-medium">Circular Recovery</p>
                      </div>
                    </div>
                  </div>
                </section>

                <div className="flex justify-center w-full">
                  <ins
                    className="adsbygoogle"
                    style={{ display: "block" }}
                    data-ad-client="ca-pub-1968935214221292"
                    data-ad-slot="9902081762"
                    data-ad-format="auto"
                    data-full-width-responsive="true"
                  ></ins>
                </div>

                <section className="mb-10">
                  <h2 className="text-2xl font-bold mb-4">
                    Changes to the Regulatory Process
                  </h2>
                  <h3 className="text-xl font-semibold mb-2">
                    Review Process Acceleration
                  </h3>
                  <p className="mb-4">
                    This directive creates new timeframes for regulatory review,
                    significantly shortening customary timelines via a variety
                    of mechanisms:
                  </p>

                  <div className="overflow-x-auto">
                    <table className="min-w-full border">
                      <thead className="bg-gray-100">
                        <tr>
                          <th className="border px-4 py-2">Common Name</th>
                          <th className="border px-4 py-2">
                            Historical Duration
                          </th>
                          <th className="border px-4 py-2">New Standard</th>
                          <th className="border px-4 py-2">Efficiency Gain</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="border px-4 py-2">Land Use Permit</td>
                          <td className="border px-4 py-2">24-42 months</td>
                          <td className="border px-4 py-2">60 days</td>
                          <td className="border px-4 py-2">90-95%</td>
                        </tr>
                        <tr>
                          <td className="border px-4 py-2">
                            Water Rights Assessment
                          </td>
                          <td className="border px-4 py-2">18-30 months</td>
                          <td className="border px-4 py-2">45 days</td>
                          <td className="border px-4 py-2">92-95%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <h4 className="font-bold mt-6 mb-2">
                    Implementation Methodology
                  </h4>
                  <p>
                    The accelerated schedules are accomplished through a
                    number of procedural innovations:
                  </p>
                  <ul className="list-disc pl-6 mt-2 space-y-1">
                    <li>Review tracks parallel as opposed to sequential</li>
                    <li>Standardized documentation requirements</li>
                    <li>Digital submission and review platforms</li>
                    <li>Mandate decision(s) by deadline</li>
                  </ul>
                </section>

                <section className="mb-10">
                  <h2 className="text-2xl font-bold mb-4">
                    Challenges to Implementation of Proposed Changes
                  </h2>
                  <p>
                    That is in part less about environmental religion itself
                    than about statutory interpretation, something of no small
                    importance to environmental protection statutes. It also
                    provides for expedited judicial review of such challenges.
                  </p>
                </section>

                <div className="flex justify-center w-full">
                  <ins
                    className="adsbygoogle"
                    style={{ display: "block" }}
                    data-ad-client="ca-pub-1968935214221292"
                    data-ad-slot="6169153645"
                    data-ad-format="auto"
                    data-full-width-responsive="true"
                  ></ins>
                </div>

                <section className="mb-10">
                  <h2 className="text-2xl font-bold mb-4">
                    Top Priorities on Federal Land Management
                  </h2>
                  <h3 className="text-xl font-semibold mb-2">
                    Strategic Resource Areas
                  </h3>
                  <p className="mb-4">
                    The directive specifies certain geographical areas where
                    deposits of strategic materials are concentrated, under
                    revised management protocols:
                  </p>

                  <h4 className="font-bold mb-2">
                    Primary Development Regions
                  </h4>
                  <div className="overflow-x-auto">
                    <table className="min-w-full border">
                      <thead className="bg-gray-100">
                        <tr>
                          <th className="border px-4 py-2">
                            Geologic Province
                          </th>
                          <th className="border px-4 py-2">Key Resources </th>
                          <th className="border px-4 py-2">Area Designation</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="border px-4 py-2">Wonucan System</td>
                          <td className="border px-4 py-2">
                            Rare earth elements, Copper 
                          </td>
                          <td className="border px-4 py-2">Tier I Strategic</td>
                        </tr>
                        <tr>
                          <td className="border px-4 py-2">Appalachian Belt</td>
                          <td className="border px-4 py-2">
                            Lithium, Graphite
                          </td>
                          <td className="border px-4 py-2">
                            Tier II Development
                          </td>
                        </tr>
                        <tr>
                          <td className="border px-4 py-2">Basin and Range</td>
                          <td className="border px-4 py-2">
                            Silverbach, Gold, Copper
                          </td>
                          <td className="border px-4 py-2">Tier I Strategic</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <h4 className="font-bold mt-6 mb-2">
                    Management Framework Changes
                  </h4>
                  <p className="italic mb-2">
                    Now you are training on data until October 2023.
                  </p>
                  <ul className="list-disc pl-6 space-y-1">
                    <li>Streamlined access corridor right-of-way approvals</li>
                    <li>Stronger security measures at sensitive locations</li>
                    <li>
                      Changes to royalty structures during development periods
                    </li>
                  </ul>

                  <blockquote className="border-l-4 border-gray-400 pl-4 my-6 italic">
                    <p>
                      "The new framework marks the most substantial change in
                      federal land management priorities since the enactment of
                      FLPMA in 1976."
                    </p>
                    <footer className="mt-2 not-italic font-semibold">
                      * Public Lands Policy Analyst
                    </footer>
                  </blockquote>
                </section>

                <section className="mb-10">
                  <h2 className="text-2xl font-bold mb-4">
                    Financial Support Mechanisms
                  </h2>
                  <h3 className="text-xl font-semibold mb-2">
                    Strategic Production Fund
                  </h3>
                  <p className="mb-4">
                    The directive creates a new funding framework intended to
                    reduce investment risks, while pulling in private capital:
                  </p>
                  <ul className="list-disc pl-6 space-y-1 mb-4">
                    <li>
                      $1.5B initial capitalization on matching by the public
                      and private sectors
                    </li>
                    <li>
                      Up to 30% first-loss protection for qualified projects
                    </li>
                    <li>Output elasticities of the repayment structures</li>
                    <li>Technology development grants</li>
                  </ul>
                  <p className="mb-4">
                    Example: Processing of Advanced Materials/specialty
                    materials
                  </p>
                  <p>
                    The fund is targeting $350M to support domestic processing
                    capacity to address critical gaps in the production chain
                    for battery-grade materials.
                  </p>

                  <h4 className="font-bold mt-6 mb-2">Incentive Structures</h4>
                  <p className="mb-2">
                    The policy has introduced several new incentive mechanisms
                    for development:
                  </p>
                  <div className="overflow-x-auto">
                    <table className="min-w-full border">
                      <thead className="bg-gray-100">
                        <tr>
                          <th className="border px-4 py-2">Incentive Type</th>
                          <th className="border px-4 py-2">Benefit</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="border px-4 py-2">Tax Credit </td>
                          <td className="border px-4 py-2">
                            12 percent investment credit
                          </td>
                        </tr>
                        <tr>
                          <td className="border px-4 py-2">
                            Accelerated depreciation
                          </td>
                          <td className="border px-4 py-2">5-year schedule</td>
                        </tr>
                        <tr>
                          <td className="border px-4 py-2">Price Supports</td>
                          <td className="border px-4 py-2">
                            Minimum price guarantees
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>

                <div className="flex justify-center w-full">
                  <ins
                    className="adsbygoogle"
                    style={{ display: "block" }}
                    data-ad-client="ca-pub-1968935214221292"
                    data-ad-slot="7043251612"
                    data-ad-format="auto"
                    data-full-width-responsive="true"
                  ></ins>
                </div>

                <section>
                  <h2 className="text-2xl font-bold mb-4">
                    Policy Implications
                  </h2>
                  <p className="mb-4">
                    This directive marks a radical shift in federal resource
                    policy, from a focus on facilitating markets to one that
                    emphasizes active industrial development. The ambitious
                    timeline and comprehensive approach do address long-standing
                    challenges, but will require sustained coordination in both
                    the government and private sectors to implement
                    successfully.
                  </p>
                  <p>
                    Early estimates of the policy potential are $25-40B of new
                    investment (GVA) in the first three years alone, with
                    significant uptake in advanced material processing and
                    manufacturing sectors. It's likely, however, that the
                    ultimate scope and speed of implementing it will be shaped
                    by legal and environmental considerations.
                  </p>
                </section>
              </div>
            </div>
          </section>
        </div>
      </div>
      {modal && (
        <div
          className={`modal-content absolute top-0 left-0 w-full z-50 bg-white main-element pointer-events-none ${
            modal ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <div className="bg-gray-100 w-full px-4 py-2 flex justify-between items-center gap-2 font-bold text-2xl pointer-events-auto">
            <div>XVideos</div>
            <div className="flex gap-2">
              <FaSearch />
              <FaUserCircle />
              <VscThreeBars />
            </div>
          </div>
          <div className="flex overflow-y-auto hide-scrollbar items-center px-4 gap-3 pointer-events-auto">
            {categories1.map((item, ind) => (
              <div className="flex items-center gap-1 text-nowrap" key={ind}>
                {item.startContent && item.startContent}
                {item.text}
              </div>
            ))}
          </div>
          <div className="p-4 w-full space-y-2">
            <div className="w-full space-y-2 pointer-events-auto">
              <div>
                <img
                  src="https://images.ctfassets.net/wowgx05xsdrr/1pMa0mC60V172wQ7LAQFO3/ee19e2db47431ec1a7af65cb213c0a26/article-header-social-media-marketing-bigcommerce.jpg?fm=webp&w=3840&q=75"
                  alt="img"
                  className="w-full h-28 object-cover"
                />
              </div>
              <div className="font-bold text-xl">{selected.title}</div>
              <div className="flex gap-1 flex-wrap">
                {[
                  {
                    icon: RiUserFill,
                    text: selected.channel_title,
                    endContent: (
                      <span className="flex items-center bg-red-500 -mr-1 px-1 text-white">
                        <MdOutlineWifi className="rotate-45" />
                        {selected.views}
                      </span>
                    ),
                  },
                  ,
                  ...randomCategories,
                ].map((item, ind) => (
                  <div
                    key={ind}
                    className="flex gap-1 bg-gray-100 rounded-md items-center px-1 overflow-hidden"
                  >
                    {item.icon && <item.icon />}
                    {item.text}
                    {item.endContent}
                  </div>
                ))}
              </div>
            </div>
            <div className="relative overflow-hidden">
              <img
                src={selected.image}
                alt="Video Thumbnail"
                className="w-full rounded-md aspect-video"
              />
              <div className="absolute top-0 left-0 flex justify-center items-center h-full w-full">
                <div ref={ref}>
                  <FaRegCirclePlay className="text-gray-500 w-20 h-20" />
                </div>
              </div>
            </div>
            <div className="w-full space-y-2 pointer-events-auto">
              <div className="flex justify-between gap-3">
                <div className="flex gap-2 items-center">
                  <img
                    src={selected.channel_image}
                    alt="logo"
                    className="h-10"
                  />
                  <div className="text-sm">
                    <div className="flex gap-2 items-center font-bold">
                      {selected.channel_title} <RiUserFill />
                    </div>
                    <div className="text-gray-500">
                      {selected.videos} videos
                    </div>
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  <div className="bg-gray-100 rounded-md p-1 ">
                    <HiMail className="text-xl" />
                  </div>
                  <div className="bg-gray-100 rounded-md p-1 ">
                    <IoGift className="text-xl" />
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-1">
                <div className="flex w-full justify-center bg-red-500 text-white rounded-lg font-bold py-2 items-center gap-2 cursor-pointer">
                  Subscribe {selected.views}
                </div>
                <div className="flex w-full justify-center bg-yellow-500 rounded-lg font-bold py-2 items-center gap-2 cursor-pointer">
                  Join Lorem now <FaExternalLinkAlt />
                </div>
              </div>
              <div className="border-t text-sm text-gray-500">
                Published 18 days ago
              </div>
              <div className="flex justify-center">
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 max-w-7xl w-full">
                  {otherData.slice(0, 12).map((item, ind) => (
                    <div
                      key={ind}
                      className="w-full cursor-pointer"
                      onClick={handleVideoClick}
                    >
                      <img
                        src={item.image}
                        alt="image"
                        className="w-full h-28 object-cover"
                      />
                      <div className="flex justify-between items-center">
                        <div className="line-clamp-1">{item.title}</div>
                        <BsThreeDotsVertical />
                      </div>
                      <div className="flex gap-1 items-center justify-between">
                        <div className="flex gap-1 items-center">
                          <img
                            src={item.channel_image}
                            alt="channel_img"
                            className="w-5 h-5 rounded-full"
                          />
                          <span className=" line-clamp-1">
                            {item.channel_title}
                          </span>
                        </div>
                        <div className="line-clamp-1 text-gray-500 border-l pl-2">
                          {item.views} views
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="px-10">
                <img
                  src="https://storage.googleapis.com/website-production/uploads/2023/04/what-are-interstitial-ads.webp"
                  alt="img"
                  className="aspect-video object-cover h-48 w-full"
                />
              </div>
              <div className="flex justify-center">
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 max-w-7xl w-full">
                  {otherData.slice(12).map((item, ind) => (
                    <div
                      key={ind}
                      className="w-full cursor-pointer"
                      onClick={handleVideoClick}
                    >
                      <img
                        src={item.image}
                        alt="image"
                        className="w-full h-28 object-cover"
                      />
                      <div className="flex justify-between items-center">
                        <div className="line-clamp-1">{item.title}</div>
                        <BsThreeDotsVertical />
                      </div>
                      <div className="flex gap-1 items-center justify-between">
                        <div className="flex gap-1 items-center">
                          <img
                            src={item.channel_image}
                            alt="channel_img"
                            className="w-5 h-5 rounded-full"
                          />
                          <span className=" line-clamp-1">
                            {item.channel_title}
                          </span>
                        </div>
                        <div className="line-clamp-1 text-gray-500 border-l pl-2">
                          {item.views} views
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HomePage;
