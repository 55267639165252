export default [
  {
    title: "Travel Vlogger Tejaswini explores cultural heritage",
    image: "/image/image-1.png",
    channel_image: "/image/channel-1.png",
    channel_title: "Kilash",
    views: "23.9k",
    videos: "43",
  },
  {
    title: "Documentary on Japanese street food with Risa O",
    image: "/image/image-2.png",
    channel_image: "/image/channel-2.png",
    channel_title: "School Girls HD Channel",
    views: "154k",
    videos: "1.6K",
  },
  {
    title: "Teenager explores new hobbies after school",
    image: "/image/image-3.png",
    channel_image: "/image/channel-3.png",
    channel_title: "Party Land",
    views: "3.2k",
    videos: "105",
  },
  {
    title: "Sharing a cozy evening with family and friends",
    image: "/image/image-4.png",
    channel_image: "/image/channel-4.png",
    channel_title: "Party Land",
    views: "3.2k",
    videos: "105",
  },
  {
    title: "Office team-building activities and fun moments",
    image: "/image/image-5.png",
    channel_image: "/image/channel-5.png",
    channel_title: "Salni bs",
    views: "5.9k",
    videos: "34",
  },
  {
    title: "Cultural festival highlights with Komal",
    image: "/image/image-6.png",
    channel_image: "/image/channel-6.png",
    channel_title: "Sexy Komal",
    views: "104.8k",
    videos: "321",
  },
  {
    title: "Brunette Susy Gala explores outdoor adventures",
    image: "/image/image-7.png",
    channel_image: "/image/channel-7.png",
    channel_title: "Enjoy X",
    views: "18.4k",
    videos: "60",
  },
  {
    title: "Exploring Indian culture with Shraddha Pandey",
    image: "/image/image-8.png",
    channel_image: "/image/channel-8.png",
    channel_title: "Shraddha Pandey",
    views: "12.7k",
    videos: "62",
  },
  {
    title: "Family gathering during Holi celebrations",
    image: "/image/image-9.png",
    channel_image: "/image/channel-9.png",
    channel_title: "Happyhome",
    views: "41.6k",
    videos: "65",
  },
  {
    title: "Creative art projects with talented individuals",
    image: "/image/image-10.png",
    channel_image: "/image/channel-10.png",
    channel_title: "Erotic-Planet",
    views: "9.9k",
    videos: "3.3k",
  },
  {
    title: "Dynamic sports highlights",
    image: "/image/image-11.png",
    channel_image: "/image/channel-11.png",
    channel_title: "Alyx Star",
    views: "123k",
    videos: "2.5k",
  },
  {
    title: "Exciting cooking challenges",
    image: "/image/image-12.png",
    channel_image: "/image/channel-12.png",
    channel_title: "Merrie_Bock",
    views: "114k",
    videos: "4.8k",
  },
  {
    title: "Exploring German culture through food",
    image: "/image/image-13.png",
    channel_image: "/image/channel-13.png",
    channel_title: "Erotic-Planet",
    views: "9.9k",
    videos: "3.3k",
  },
  {
    title: "Morning routines and productivity tips",
    image: "/image/image-14.png",
    channel_image: "/image/channel-14.png",
    channel_title: "cumatozz",
    views: "55.4k",
    videos: "127",
  },
  {
    title: "Funny pranks and reactions",
    image: "/image/image-15.png",
    channel_image: "/image/channel-15.png",
    channel_title: "cumatozz",
    views: "55.4k",
    videos: "127",
  },
  {
    title: "Amateur couple’s travel adventures",
    image: "/image/image-16.png",
    channel_image: "/image/channel-16.png",
    channel_title: "cumatozz",
    views: "55.4k",
    videos: "127",
  },
  {
    title: "Girlfriend’s morning routine and makeup tips",
    image: "/image/image-17.png",
    channel_image: "/image/channel-17.png",
    channel_title: "NfBusty",
    views: "66.5k",
    videos: "34",
  },
  {
    title: "Sibling bonding activities",
    image: "/image/image-18.png",
    channel_image: "/image/channel-18.png",
    channel_title: "newsensations",
    views: "60.1k",
    videos: "1492",
  },
  {
    title: "Teenage adventure stories",
    image: "/image/image-19.png",
    channel_image: "/image/channel-19.png",
    channel_title: "Teen Fidelity",
    views: "60.1k",
    videos: "1492",
  },
  {
    title: "Family-friendly mystery stories",
    image: "/image/image-20.png",
    channel_image: "/image/channel-20.png",
    channel_title: "Pure Taboo",
    views: "339k",
    videos: "44",
  },
  {
    title: "Couple’s creative art sessions",
    image: "/image/image-21.png",
    channel_image: "/image/channel-21.png",
    channel_title: "FeralBerryy",
    views: "60.5k",
    videos: "90",
  },
  {
    title: "Roommate pranks and fun moments",
    image: "/image/image-22.png",
    channel_image: "/image/channel-22.png",
    channel_title: "FantasyBabe",
    views: "36.6k",
    videos: "64",
  },
  {
    title: "Indian traditional dance performances",
    image: "/image/image-23.png",
    channel_image: "/image/channel-23.png",
    channel_title: "indianXworld",
    views: "97.5k",
    videos: "109",
  },
  {
    title: "Fun activities with friends",
    image: "/image/image-24.png",
    channel_image: "/image/channel-24.png",
    channel_title: "EnjoyX",
    views: "18.4k",
    videos: "60",
  },
  {
    title: "Wellness and relaxation sessions",
    image: "/image/image-25.png",
    channel_image: "/image/channel-25.png",
    channel_title: "Adult Time",
    views: "245.3k",
    videos: "102",
  },
];
