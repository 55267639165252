import React from "react";

export function Navbar() {
  const [scrolled, setScrolled] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener("scroll", () =>
      setScrolled(window.scrollY > 0 ? true : false)
    );
  }, []);

  return (
    <nav
      className={`sticky top-0 z-50 transition-all bg-white ${
        scrolled ? "shadow-sm border-b" : ""
      }`}
    >
      <div className="container mx-auto max-w-[950px] flex items-center justify-between p-4">
        <div className="text-lg font-bold text-blue-900">Logo</div>
        <div className="items-center gap-2 flex">
          <button className="text-gray-900">Sign In</button>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
